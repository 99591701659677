import React from 'react';

const ThankyouSection = (props) => {

    return (
        <section className="thankyou-section-1">
            <div className="container">
                <div className='about-detail'>
                    <div className="row">
                        <div className="col-lg-16">
                            <div className="title-block">
                                <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                                <p className="section-description">{props.mainDescription}</p>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-7">
                            <div className="image-block">
                                <img
                                    src={props.image1x?.sourceUrl}
                                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                    width={props.image1x?.width}
                                    alt={props.image1x?.altText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ThankyouSection